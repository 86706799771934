import jsPDF from 'jspdf';

let debug = false;

export async function generatePdfDocument(
    selectorImg,
    selectorList,
    filename,
    html2canvas,
    debugT = undefined
) {
    if (debugT) debug = true;

    const elPages = document.querySelectorAll(selectorImg);

    let doc = new jsPDF();

    // Todo - Handle for multiple pages.
    processSelectableTextEmbedding(doc, selectorList);

    for (let i = 0; i < elPages.length; i++) {
        let elPage = elPages[i];

        //Add the background image
        let output = await html2canvas(elPage, {type: 'dataURL', scale: 1});
        doc.addImage(output, 'PNG', 0, 0, 1125 / 5.5, 1638 / 5.5, '', 'NONE');

        if (i < elPages.length - 1) doc.addPage();
    }

    const pdfBlob = new Blob([doc.output('blob', {filename})], {type: 'application/pdf'});
    return pdfBlob;
}

/**
 * Display a PDF in a fullscreen iframe
 * @param {String} pdfBlob - the PDF as a Blob
 * @returns The iframe in which the PDF is displayed
 */
export const displayPdfDocument = async (pdfBlob) => {
    const iframe = document.createElement('iframe');

    // Style the iframe
    iframe.classList.add('fullscreen');
    iframe.setAttribute('frameborder', '0');
    iframe.setAttribute('allowfullscreen', '');

    // Create the PDF.js viewer URL and set it as the iframe src
    const publicPath = process.env.BASE_URL;
    const viewerUrl = 'lib/pdfjs-4.0.379-dist/web/viewer.html';
    const fileUrl = URL.createObjectURL(pdfBlob);
    const src = `${publicPath}${viewerUrl}?file=${encodeURIComponent(fileUrl)}`;
    iframe.src = src;

    // Add the iframe to the document body
    document.body.appendChild(iframe);
    return iframe;
};

function processSelectableTextEmbedding(doc, selectorList) {
    selectorList.forEach((selectorItem) => {
        let selectorStr;
        let selectorObj = {};
        if (selectorItem?.selector) {
            selectorStr = selectorItem.selector;
            selectorObj = selectorItem;
        } else {
            selectorStr = selectorItem;
        }

        document.querySelectorAll(selectorStr).forEach((el) => {
            writeElementToPdf(doc, el, selectorObj);
        });
    });
}

function writeElementToPdf(doc, el, selectorObjT) {
    let selectorObj = {
        align: 'left',
        xAdjust: 0,
        yAdjust: 0,
        xMultiple: 1 / 7.3,
        yMultiple: 1 / 7,
        x: 0,
        y: 0,
        ...selectorObjT,
    };

    //Debug params
    //console.log(`Element: ${el.innerText}`);
    doc.setTextColor(555, 0, 0);

    let options = {};
    if (!debug) options = {renderingMode: 'invisible'};

    //Set font size
    let fontSize = window.getComputedStyle(el, null).getPropertyValue('font-size');
    fontSize = parseFloat(fontSize) / 2.5;
    doc.setFontSize(fontSize);

    //Set font weight
    //let fontWeight = window.getComputedStyle(el, null).getPropertyValue('font-weight');
    //fontWeight = parseFloat(fontWeight) / 2.5;
    doc.setFont(undefined, 'bold'); //Everything should be bold

    //Position text
    let rect = el.getBoundingClientRect();
    selectorObj.y = rect.y;

    if (selectorObj.align == 'center') {
        selectorObj.x = rect.x + rect.width / 2;
        options.align = selectorObj.align;
        //console.log(`centerStart=${rect.x},centerEnd=${selectorObj.x}`);
    } else selectorObj.x = rect.x;

    doc.text(
        el.innerText,
        selectorObj.x * selectorObj.xMultiple + selectorObj.xAdjust,
        selectorObj.y * selectorObj.yMultiple + selectorObj.yAdjust,
        options
    );
}
