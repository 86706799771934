export const getLogo = (brandLogo) => {
    switch (brandLogo) {
        case 'EVO':
        case 'VIVA':
        case 'ICL':
            return brandLogo;
        case 'EVO+':
            return 'EVO';
        default:
            return 'ICL';
    }
};

export const inventoryReportSelectorList = [
    {
        selector: '#headerRight',
        xAdjust: 0,
        yAdjust: -2,
    },
    {
        selector: '#lastName',
        xAdjust: 0,
        yAdjust: 2.5,
    },
    {
        selector: '#firstName',
        xAdjust: 0,
        yAdjust: -1.5,
    },
    {
        selector: '#gender',
        xAdjust: 0,
        yAdjust: -3.5,
    },
    {
        selector: '.patientDataLabel',
        xAdjust: 0,
        yAdjust: -4.5,
    },
    {
        selector: '.patientDataValue',
        xAdjust: 0,
        yAdjust: -4.5,
    },
    //'.noEmphasis', //Todo - make sure these fields are covered
    {
        selector: '.eyeContainer #odEyeTitle',
        xAdjust: 0,
        yAdjust: 3.5,
    },
    {
        selector: '#targetLensLabel',
        yAdjust: -4.5,
        xAdjust: 26,
        align: 'center',
    },
    {
        selector: '#targetLensName',
        yAdjust: -3.5,
    },
];

export const preOpReportSelectorList = [
    {
        selector: '#headerRight',
        xAdjust: 0,
        yAdjust: 4,
    },
    {
        selector: '#lastName',
        xAdjust: 0,
        yAdjust: 8,
    },
    {
        selector: '#firstName',
        xAdjust: 0,
        yAdjust: 4,
    },
    {
        selector: '#gender',
        xAdjust: 0,
        yAdjust: 2,
    },
    {
        selector: '.patientDataLabel',
        xAdjust: 0,
        yAdjust: 1,
    },
    {
        selector: '.patientDataValue',
        xAdjust: 0,
        yAdjust: 1,
    },
    //'.noEmphasis', //Todo - make sure these fields are covered
    {
        selector: '.eyeContainer #odEyeTitle',
        xAdjust: 0,
        yAdjust: 8,
    },
    {
        selector: '#preopContainer .sectionTitle',
        xAdjust: 0,
        yAdjust: 6,
    },
    {
        selector: '#calculationContainer .sectionTitle',
        xAdjust: 0,
        yAdjust: 5,
    },
    {
        selector: '#lensesContainer .sectionTitle',
        xAdjust: 0,
        yAdjust: 2,
    },
    {
        selector: '.previousInterventionsContainer > div',
        align: 'center',
    },
    {
        selector: '.calculationTitleContainer > div',
        align: 'center',
    },
    {
        selector: '#preopContainer th',
        align: 'center',
        yAdjust: 1,
    },
    {
        selector: '#preopContainer td:not(.preopRowTitle)',
        align: 'center',
        yAdjust: 1.5,
    },
    {
        selector: '.preopRowTitle',
        xAdjust: 2,
        yAdjust: 1.5,
    },
    {
        selector: '.preopDataLabel',
        yAdjust: -1,
    },
    {
        selector: '.preopDataValue',
        align: 'center',
        yAdjust: -1,
    },
    {
        selector: '#calculationContainer th',
        align: 'center',
        yAdjust: -1.5,
    },
    {
        selector: '#calculationContainer td',
        align: 'center',
        yAdjust: -1.5,
    },
    //'.dUnit',
    {
        selector: '.calculationFooterLeftContainer > div',
        yAdjust: -3.5,
    },
    {
        selector: '#lensesContainer th',
        align: 'center',
        yAdjust: -3.5,
    },
    {
        selector: '#lensesContainer td',
        align: 'center',
        yAdjust: -3.5,
    },
    {
        selector: '.emptyData',
        align: 'center',
        yAdjust: -2.5,
    },
];

export const iodReportSelectorList = [
    {
        selector: '#headerRight',
        xAdjust: -6,
        yAdjust: 4,
    },
    {
        selector: '#lastName',
        xAdjust: 0,
        yAdjust: 9,
    },
    {
        selector: '#firstName',
        xAdjust: 0,
        yAdjust: 5,
    },
    {
        selector: '#gender',
        xAdjust: 0,
        yAdjust: 2,
    },
    {
        selector: '.patientDataLabel',
        xAdjust: 0,
        yAdjust: 2,
    },
    {
        selector: '.patientDataValue',
        xAdjust: -5,
        yAdjust: 2,
    },
    //'.noEmphasis', //Todo - make sure these fields are covered
    {
        selector: '.eyeContainer #odEyeTitle',
        xAdjust: 0,
        yAdjust: 10,
    },
    {
        selector: '#preopContainer .sectionTitle',
        xAdjust: 0,
        yAdjust: 11,
    },
    {
        selector: '#preopContainer th',
        align: 'center',
        yAdjust: 3,
    },
    {
        selector: '#preopContainer td',
        align: 'center',
        yAdjust: 4.5,
    },
    {
        selector: '.preopDataLabel',
        yAdjust: -1,
    },
    {
        selector: '#eyeResultHeaderText3',
        yAdjust: 2,
    },
    {
        selector: '#eyeResultHeaderText4',
        yAdjust: 2,
    },
    {
        selector: '#bottomRow_leftCol_Row1',
        yAdjust: 5,
    },
    {
        selector: '#bottomRow_leftCol_Row2',
        yAdjust: 5,
    },
    {
        selector: '#bottomRow_leftCol_Row3',
        yAdjust: 5,
    },
    {
        selector: '#bottomRow_leftCol_Row4',
        yAdjust: 5,
    },
    {
        selector: '.previousInterventionsContainer > div',
        align: 'center',
        yAdjust: 1,
    },
    {
        selector: '#confirmSN',
        yAdjust: 3,
    },
    {
        selector: '#footerLeft',
        yAdjust: 0.5,
    },
    {
        selector: '#calculationVersionContainer',
        yAdjust: 1,
        xAdjust: -2,
    },
];
